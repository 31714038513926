export default () => ({
  status: "active",
  name: "",
  category: null,
  telegramGroup: null,
  minStock: 0,
  minSize: 0,
  scheduleDays: [],
  scheduleTime: "",
  imagesQuantity: 0,
  bots: [],
  typeOfPosts: [],
  hasUniqueSizes: false,
});
